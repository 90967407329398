<careweb-help></careweb-help>
<div class="full-careweb-container">

    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
                (launchSearch)="launchSearchFilter()"></careweb-accordion-header-buttons>
         <form (ngSubmit) = launchSearchFilter() #form="ngForm" >
            <div class="filter-content">
                <h3 style="color:white; margin-left:10px">Avis de reception (RSP)</h3>
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <!-- Numéro du lot -->
					<careweb-accordion-group id="numero_lot" title="Numéro du lot" [isDateRangeValid] = "isDateComptableRangeValid">
						<input type="text" id="input_num_lot_from" class="form-control" [submitOnEnter]="form"
						    onKeyPress="if(this.value.length==3) return false;" [(ngModel)]="filtersRSP.numLot" maxlength="3"
						    name="numLot"
						    onkeyup="this.value=this.value===''?null:this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'')"
						    onkeydown="this.value=this.value===''?null:this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'')"
						    oninput="this.value=this.value===''?null:this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'');"
						    onpaste="if(this.value.length==3) return false;" />
					</careweb-accordion-group>
                    <!-- Date Comptable -->
                    <careweb-accordion-group id="date_comptable" title="Date comptable" #dateComptableAccordion>
                        <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filtersRSP.dateComptableFrom" id="date_comptable"
                            [(modelTo)]="filtersRSP.dateComptableTo" [maxDate]="null" (dateRangeValidityChange) = "checkDateComptaleRangeValidity($event)" (onEnter) = "onKeyDown($event)" #dateComptable ></careweb-date-from-to-filter>
                    </careweb-accordion-group>
                    <!--  ORGANISME -->
                    <careweb-filter-multiple id="organismes" property="organismes" title="Organisme"
                     [(criterias)]="filtersRSP.organismes" (criteriasSubmitted)="getOrganismeList($event)"
                     [isDateRangeValid] = "isDateComptableRangeValid"
                     ></careweb-filter-multiple>
                     <!-- Code organisme émetteur -->
                     <careweb-accordion-group id="code_organisme_emetteur" title="Code organisme" [submitOnEnter]="form" [isDateRangeValid]="isDateComptableRangeValid">
                         <input type="text" id="input_organisme_emetteur" class="form-control" [(ngModel)]="filtersRSP.idOrganisme" name="codeOrganisme" onlyNum="10"/>
                    </careweb-accordion-group>
                    <!-- Montant -->
                    <careweb-accordion-group id="montantRSP" title="Montant" [submitOnEnter] = "form" [isDateRangeValid] = "isDateComptableRangeValid" >
                        <careweb-filter-choice-exacte-plage [id]="'montantRsp'"
                            [(filterChoiceFrom)]="this.filtersRSP.montantRspFrom" 
                            [(filterChoiceTo)]="this.filtersRSP.montantRspTo"
                            (filterChange)="this.onChoiceChange($event, 'montantRsp')">
                            <input EXACTE type="text" id="input_exacte_montantRsp" class="form-control" placeholder="0.00" [(ngModel)]="this.filtersRSP.montantRspExacte" name="montantRspExacte" carewebOnlyDouble/>
                            <input FROM type="text" id="input_from_montantRsp" class="form-control" placeholder="0.00" [(ngModel)]="this.filtersRSP.montantRspFrom" name="montantRspFrom" carewebOnlyDouble/>
                            <div FROM *ngIf="montantRspMinError"  class="text-danger">Indiquer un montant minimum</div>
                            <input TO type="text" id="input_to_montantRsp" class="form-control" placeholder="0.00" [(ngModel)]="this.filtersRSP.montantRspTo" name="montantRspTo" carewebOnlyDouble/>
                            <div TO *ngIf="montantRspMaxError"  class="text-danger">Indiquer un montant maximum</div>
                        </careweb-filter-choice-exacte-plage>
                    </careweb-accordion-group>
                    <!-- Montant d'un groupe -->
                    <careweb-accordion-group id="montantGroupeRSP" title="Montant d'un lot" [submitOnEnter] = "form" [isDateRangeValid] = "isDateComptableRangeValid" >
                        <careweb-filter-choice-exacte-plage [id]="'montantGroupeRsp'" 
                            [(filterChoiceFrom)]="this.filtersRSP.montantGroupeRspFrom" 
                            [(filterChoiceTo)]="this.filtersRSP.montantGroupeRspTo"
                            (filterChange)="this.onChoiceChange($event, 'montantGroupeRsp')">
                            <input EXACTE type="text" id="input_exacte_montantGroupeRsp" class="form-control" placeholder="0.00" [(ngModel)]="this.filtersRSP.montantGroupeRspExacte" name="montantGroupeRspExacte" carewebOnlyDouble/>
                            <input FROM type="text" id="input_from_montantGroupeRsp" class="form-control" placeholder="0.00" [(ngModel)]="this.filtersRSP.montantGroupeRspFrom" name="montantGroupeRspFrom"  carewebOnlyDouble/>
                            <div FROM *ngIf="montantGroupeRspMinError" class="text-danger">Indiquer un montant minimum</div>
                            <input TO type="text" id="input_to_montantGroupeRsp" class="form-control" placeholder="0.00" [(ngModel)]="this.filtersRSP.montantGroupeRspTo" name="montantGroupeRspTo" carewebOnlyDouble/>
                            <div TO *ngIf="montantGroupeRspMaxError" class="text-danger">Indiquer un montant maximum</div>
                        </careweb-filter-choice-exacte-plage>
                    </careweb-accordion-group>
                    <!--  Libellé de virement -->
                    <careweb-accordion-group id="libelleVirement" title="Libellé de virement" [submitOnEnter] = "form" [isDateRangeValid] = "isDateComptableRangeValid">
                        <input type="text" id="libelleVrt" class="form-control" [(ngModel)]="filtersRSP.libelleVirement" name="libelleVirement"/>
                    </careweb-accordion-group>

                    <!-- Mettre en avant les RSP de + 30j -->
                 <!--   <careweb-accordion-group id="rsp30Jours" title="Mettre en avant les RSP de +30j">
                        <ng-select name="rsp30Jours" id="rsp30Jours" placeholder="Mettre en avant les RSP de + 30j"
                                   [searchable]="false" [(ngModel)]="filtersRSP.rsp30Jours" appendTo=".filter-container"
                                    (keydown.enter)="onKeyDown($event)">
                            <ng-option [value]="''"> </ng-option>
                            <ng-option [value]="true">Oui</ng-option>
                            <ng-option [value]="false">Non</ng-option>
                        </ng-select>
                    </careweb-accordion-group>-->
                    <!-- RSP indu -->
                    <careweb-accordion-group id="RspIndu" title="Afficher les Indus">
                        <ng-select name="RspIndu" id="InduRSP" placeholder="Afficher les Indus"
                                   [searchable]="false" [(ngModel)]="filtersRSP.rspIndu" appendTo=".filter-container"
                                   (keydown.enter)="onKeyDown($event)">
                            <ng-option id="option_" [value]="''"> </ng-option>
                            <ng-option id="option_oui" [value]="true">Oui</ng-option>
                            <ng-option id="option_non" [value]="false">Non</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                    <!-- VIREMENTS -->
                    <h3 style="color:white; margin-top:15px; margin-left:10px">Virements banque (VRT)</h3>
                    <careweb-accordion-group id="label" title="Libellé / Notes" [submitOnEnter] = "form">
                        <input type="text" id="labelVrt" class="form-control" [(ngModel)]="filtersVRT.label" name="label"/>
                    </careweb-accordion-group>

                    <careweb-accordion-group id="montantVRT" title="Montant" [submitOnEnter] = "form">
                        <careweb-filter-choice-exacte-plage [id]="'montantVrt'"
                            [(filterChoiceFrom)]="this.filtersVRT.montantFrom"
                            [(filterChoiceTo)]="this.filtersVRT.montantTo"
                            (filterChange)="this.onChoiceChange($event, 'montant')">
                            <input EXACTE type="text" id="input_exacte_montantVrt" name="montantVrtExacte" class="form-control" placeholder="0.00" [(ngModel)]="this.filtersVRT.montant" carewebOnlyPositiveDouble/>
                            <input FROM type="text" id="input_from_montantVrt" name="montantVrtFrom" class="form-control" placeholder="0.00" [(ngModel)]="this.filtersVRT.montantFrom" carewebOnlyPositiveDouble/>
                            <div FROM *ngIf="montantVrtMinError" class="text-danger">Indiquer un montant minimum</div>
                            <input TO type="text" id="input_to_montantVrt" name="montantVrtTo" class="form-control" placeholder="0.00" [(ngModel)]="this.filtersVRT.montantTo" carewebOnlyPositiveDouble/>
                            <div TO  *ngIf="montantVrtMaxError" class="text-danger">Indiquer un montant maximum</div>
                        </careweb-filter-choice-exacte-plage>
                    </careweb-accordion-group>
                    <!-- Date Opération -->
                    <careweb-accordion-group id="date_operation" title="Date opération">
                        <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filtersVRT.dateOperationFrom" id="date_operation"
                            [(modelTo)]="filtersVRT.dateOperationTo" [maxDate]="null" (onEnter) = "onKeyDown($event)"></careweb-date-from-to-filter>
                    </careweb-accordion-group>
                </mat-accordion>
            </div>
        </form>
        </div>
    </div>
    <div class="grid-container" id="grid">
        <h2 class="title-border mt-3">Rapprochements manuels
            <careweb-open-help code="help-010"></careweb-open-help>
        </h2>
        <div>
            <section>

                <div class = "sticky-header">
                    <div class="row grid-top-btn">
                        <div class="col-md-4 filter">
                            <button type="button" id="filter" name="btn_toggle_filter" class="btn btn-primary small left-open"
                                [hidden]="showFilter" (click)="toggleFilterDisplay()">
                                <i class="fa fa-sliders"></i>
                                Filtrer
                            </button>
                        </div>
                        <div class=" col-md-8  control">
                            <button type="button" class="btn btn-primary small" id="rapp_valider" (click)="openModalConfim()" [disabled]="!enabledToValidate()">
                                <span>Rapprocher et valider</span>
                            </button>
                        </div>
                    </div>
                    <div class="row grid-top-filter">
                        <div class="col-md-12">
                            <careweb-filter-label *ngFor="let filter of filterList" id="close_filter" (closeFilter)="removeFilter(filter.id, filter.value)">
                                <span id="{{filter.value}}" *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                            </careweb-filter-label>
                        </div>
                    </div>
                    <div class="row">
                        <!-- RSP -->
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6 filter" style="margin-top:10px">
                                    <careweb-pluralize class="filter-count ms-0" id="results" [messageKey]="'Résultat(s) trouvé(s)'"
                                        [count]="agDemoComponent.totalElement"></careweb-pluralize>
                                </div>
                                <div class="col-md-6 conf-col" style="text-align:right">
                                    <div class="careweb-toolbar">
                                        <button type="button" id="modal_solder" class="btn btn-secondary small" (click)="openPointerSolderModal('solder')" [disabled]="!agDemoComponent.isRspSoldable">
                                            <span>Solder</span>
                                        </button>
                                        <careweb-pagination-size id="dv_transactions_paging_RSP"
                                            [pageSizes]="[10, 20, 30, 50, 100]" [(ngModel)]="agDemoComponent.rspGridDataRaw.size"
                                            (paginationSizeChange)="agDemoComponent.onRspPaginationSizeChange($event) ">
                                        </careweb-pagination-size>
                                        <span class="btn btn-action small" id="reset_grid" tooltip="Réinitialiser l'affichage"
                                            placement="top" container="body" name="spn_reset_grid"
                                            (click)="resetGridStateRSP()">
                                            <i class="fa fa-refresh"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="agDemoComponent.totalRspSelected == 0" class="row">
                                <div class="col">
                                    <h3 class="title-border">
                                        <div class="row">
                                            <div class="col-auto me-auto">Veuillez sélectionner des avis pour les rapprocher
                                            </div>
                                            <div class="col-auto"></div>
                                        </div>
                                        <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                                    </h3>
                                </div>
                            </div>

                            <div *ngIf="agDemoComponent.totalRspSelected > 0" class="row">
                                <div class="col">
                                    <h3 class="title-border">
                                        <div class="row">
                                            <div *ngIf="agDemoComponent.totalRspSelected > 0" class="col-auto me-auto">{{agDemoComponent.totalRspSelected}} avis selectionné(s)</div>
                                            <div *ngIf="!loadingRsp" class="col-auto" [ngClass]="agDemoComponent.totalDiffRSP.status">Ecart:
                                                {{agDemoComponent.totalDiffRSP.montant}}</div>
                                            <!--[CAW-1600-] Desactiver la checkAll & uncheckAll-->
                                            <div *ngIf="!loadingRsp" class="col-auto">Montant total: {{agDemoComponent.montantTotalSelectedRSP | formatThousands}}</div>
                                        </div>
                                        <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <!-- VRT -->
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6 filter" style="margin-top:10px">
                                    <careweb-pluralize class="filter-count ms-0" id="results" [messageKey]="'Résultat(s) trouvé(s)'"
                                        [count]="gridHelperVRT.totalRowCount"></careweb-pluralize>
                                </div>
                                <div class="col-md-6 conf-col" style="text-align:right">
                                    <div class="careweb-toolbar">
                                        <button type="button" class="btn btn-secondary small" id="modal_pointer" (click)="openPointerSolderModal('pointer')" [disabled]="!agDemoComponent.isVrtPointable">
                                            <span>Pointer</span>
                                        </button>
                                        <careweb-pagination-size id="dv_transactions_paging_VRT"
                                            [pageSizes]="[10, 20, 30, 50, 100]" [(ngModel)]="vrtGridDataRaw.size"
                                            (paginationSizeChange)="onVrtPaginationSizeChange($event)">
                                        </careweb-pagination-size>
                                        <span class="btn btn-action small" tooltip="Réinitialiser l'affichage"
                                            placement="top" container="body" name="spn_reset_grid" id="reset_filters"
                                            (click)="resetGridStateVRT()">
                                            <i class="fa fa-refresh"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="gridHelperVRT.gridApi?.getSelectedRows().length == 0" class="row">

                                <div class="col">
                                    <h3 class="title-border">
                                        <div class="row">
                                            <div class="col-auto me-auto">Veuillez sélectionner des virements pour les
                                                rapprocher</div>
                                            <div class="col-auto"></div>
                                        </div>
                                        <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                                    </h3>
                                </div>
                            </div>
                            <div *ngIf="gridHelperVRT.gridApi?.getSelectedRows().length > 0" class="row">
                                <div class="col">
                                    <h3 class="title-border">
                                        <div class="row">
                                            <div *ngIf="nbRowSelectedVRT > 0" class="col-auto me-auto">{{nbRowSelectedVRT}} virement(s) selectionné(s)
                                            </div>
                                            <div *ngIf="montantTotalSelectedVRT > 0" class="col-auto" [ngClass]="totalDiffVRT.status">Ecart:
                                                {{totalDiffVRT.montant}}</div>
                                            <!--[CAW-1600-] Desactiver la checkAll & uncheckAll-->
                                             <div *ngIf="montantTotalSelectedVRT > 0" class="col-auto">Montant total: {{montantTotalSelectedVRT | formatThousands}}</div>
                                        </div>
                                        <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                      <!--  <ag-grid-angular style="width: 100%;" class="ag-theme-balham ag-theme-rapp-manual"
                            [gridOptions]="gridHelperRSP.gridOptions">
                        </ag-grid-angular>
                        <careweb-pagination [gridApi]="gridHelperRSP.gridApi"></careweb-pagination>-->
                        <app-ag-RspGrid [rspRappSummaryDataRaw]="rspRappSummaryDataRaw" [montantTotalSelectedVrt]="montantTotalSelectedVRT"  (totalDiffVRTEvent)="gettotalDiffVRT($event)"
                            [gridHelperVRT]="gridHelperVRT"   [searchRspFilter]="searchRspFilter" #agDemoComponent ></app-ag-RspGrid>
                    </div>
                    <div class="col-md-6">
                        <ag-grid-angular style="width: 100%;" class="ag-theme-balham ag-theme-rapp-manual"
                            [gridOptions]="gridHelperVRT.gridOptions">
                        </ag-grid-angular>
                        <careweb-pagination [gridApi]="gridHelperVRT.gridApi"></careweb-pagination>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="button-bar control">
                            <button type="button" class="btn btn-primary" id="modal_confirmation" (click)="openModalConfim()" [disabled]="!enabledToValidate()">
                                <span>Rapprocher et valider</span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<div *ngIf="agDemoComponent.showRspDetail">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalRspDetail" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xxl" role="document">
            <div class="modal-content" style="padding-bottom:10px">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Détails du RSP <a
                                [routerLink]="['/rsp-details', agDemoComponent.selectedRow.idRspRappSummary]" target="_bank"><i
                                    class="fa fa-external-link careweb-link"></i></a> </h3>
                        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
                            title="Fermer" id="close_modal" (click)="closeModalRspDetail()"><span id="x" aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block">
                            <div class="row">
                                <div class="col">
                                    <fieldset class="container-fluid">
                                        <div class="row" style="height:50px">
                                            <div class="col-sm-2 col-form-label">N° lot</div>
                                            <div class="col-sm-4 form-group" style="padding-top:7px">
                                                <a [routerLink]="['/lot-details', agDemoComponent.selectedRow.fseDto.idLot]"
                                                    target="_bank" *ngIf="agDemoComponent.selectedRow.fseDto.idLot; else withoutLotLink">{{agDemoComponent.selectedRow.numLot}}</a>
                                                <ng-template #withoutLotLink>{{agDemoComponent.selectedRow.numLot}}</ng-template>
                                            </div>
                                            <div class="col-sm-2 col-form-label">N° FSE</div>
                                            <div class="col-sm-4 form-group" style="padding-top:7px">
                                                <a [routerLink]="['/fse-details', agDemoComponent.selectedRow.fseDto.idFacture]"
                                                    target="_bank" *ngIf="agDemoComponent.selectedRow.fseDto && agDemoComponent.selectedRow.fseDto.idLot; else withoutFseLink">{{agDemoComponent.selectedRow.numeroFacture}}</a>
                                                <ng-template #withoutFseLink>{{agDemoComponent.selectedRow.numeroFacture}}</ng-template>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-2 col-form-label">Organisme émetteur</div>
                                            <div class="col-sm-4 form-group">
                                                <input  id="libelleOrganisme" type="text" class="form-control" readonly
                                                    value="{{agDemoComponent.selectedRow.libelleOrganisme}}" />
                                            </div>
                                            <div class="col-sm-2 col-form-label">Date réception</div>
                                            <div class="col-sm-4 form-group">
                                                <input id="dateReception" type="text" class="form-control" readonly
                                                    value="{{agDemoComponent.selectedRow.dateReception | date:'short'}}" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-2 col-form-label">Montant demandé AMO</div>
                                            <div class="col-sm-4 form-group">
                                                <input id="montantFactureRo" type="text" class="form-control"
                                                    value="{{agDemoComponent.selectedRow.montantFactureRo | customCurrency}}"
                                                    name="montantFactureRo" readonly />
                                            </div>
                                            <div class="col-sm-2 col-form-label">Montant payé AMO</div>
                                            <div class="col-sm-4 form-group">
                                                <input type="text" class="form-control" id="montantRo"
                                                    value="{{formatWithSigne(agDemoComponent.selectedRow.montantRo,agDemoComponent.selectedRow.signe)}}"
                                                    name="montantRo"
                                                    readonly />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-2 col-form-label">Montant demandé AMC</div>
                                            <div class="col-sm-4 form-group">
                                                <input type="text" class="form-control" id="montantAMC_demande"
                                                    value="{{agDemoComponent.selectedRow.montantFactureRc | customCurrency}}"
                                                    name="montantFactureRc" readonly />
                                            </div>
                                            <div class="col-sm-2 col-form-label">Montant payé AMC</div>
                                            <div class="col-sm-4 form-group">
                                                <input type="text" class="form-control" id="montantAMC_payer"
                                                    value="{{formatWithSigne(agDemoComponent.selectedRow.montantRc,agDemoComponent.selectedRow.signe)}}"
                                                    name="montantRc"
                                                    readonly />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-2 col-form-label">Montant total demandé AMO+AMC</div>
                                            <div class="col-sm-4 form-group">
                                                <input type="text" class="form-control" id="montantAMC_AMO_demande"
                                                    value="{{agDemoComponent.selectedRow.montantTotalDemande}} €"
                                                    name="montantTotalDemande" readonly />
                                            </div>
                                            <div class="col-sm-2 col-form-label">Montant total payé AMO+AMC</div>
                                            <div class="col-sm-4 form-group">
                                                <input type="text" class="form-control" id="montantAMC_AMO_payer"
                                                    value="{{formatTotalWithSigne(agDemoComponent.selectedRow.montantTotalPaye,agDemoComponent.selectedRow.signe)}}"
                                                    name="montantTotalFacture" readonly />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-2 col-form-label">Libellé de virement 1</div>
                                            <div class="col-sm-4 form-group">
                                                <input type="text" class="form-control" id="lib_vir1"
                                                value="{{agDemoComponent.selectedRow.libelleVirement1}}" readonly />
                                            </div>
                                            <div class="col-sm-2 col-form-label">Libellé de virement 2</div>
                                            <div class="col-sm-4 form-group">
                                                <input type="text" class="form-control" id="liv_vir2"
                                                value="{{agDemoComponent.selectedRow.libelleVirement2}}" readonly />
                                            </div>
                                        </div>
                                    </fieldset>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="button-bar control">
                                        <button type="button" class="btn btn-primary"
                                            style="margin-right:15px;margin-bottom:5px;" id="close_modal"
                                            (click)="closeModalRspDetail()">
                                            <span>Fermer</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
        </div>
    </div>
</div>
