<careweb-help></careweb-help>
<div class="full-careweb-container">
  <div class="grid-container" id="grid">
    <div>
      <!--  FILTRES  -->
        <div class="row mt-3">
            <div [class]="showRappbtn ? (isSupervisor && selectedSupervisedPs === fseCriteria.numPs) ?  'col-lg-6 mb-3' : 'col-lg-6 mb-3' :  'col-lg-9 mb-3'">
                <h2 class="title-borde" *ngIf="videoVisibility"  [class]="derniereTransmission?'m-0':''">Suivre mes transmissions
                    <careweb-open-help code="help-013"></careweb-open-help>
                </h2>
                <h2 class="title-borde" *ngIf="!videoVisibility" [class]="derniereTransmission?'m-0':''">Suivre mes transmissions
                    <careweb-open-help code="help-013V2"></careweb-open-help>
                </h2>
                <span class="transmission" *ngIf="derniereTransmission" tooltip="Dernier lot reçu le {{derniereTransmission | date: 'dd/MM/YYYY à HH:mm'}}">Dernière télétransmission :
                    {{derniereTransmission | dateAgo}}</span>
            </div>
            <div *ngIf="showRappbtn" class="col-lg-3 d-flex align-items-end flex-column">
                <button (click)="btnRappClicked()" id="rappClicked" type="button" name="btn_toggle_filter" class="btn btn-primary align-left no-wrap px-2" tooltip="Le taux de rapprochement est de {{tauxRapp | number:'1.2-2':'fr-FR'}}% pour la période du {{dateDebutRapp |date: 'dd/MM/YYYY'}} au {{dateFinRapp |date: 'dd/MM/YYYY'}}."
                        placement="bottom">
                    Suivre le rapprochement bancaire
                    <span [class]="badge">{{tauxRapp | number:'1.2-2':'fr-FR'}}%</span>
                </button>
            </div>
            <div class="col-lg-3">
                <form #retourForm="ngForm" class="form-content p-relative" id="period-form">
                    <div class="row div-dropdown">
                        <button id="periode" class="btn btn-action align-left w-100 no-wrap" data-toggle="collapse" (click)="isCollapsed = !isCollapsed">
                            <i class="fa fa-calendar"></i>
                            Période du {{startDate | date:'shortDate'}} au {{endDate | date:'shortDate'}}
                            <i class="fa fa-sort-down p-relative"></i>
                        </button>
                    </div>
                    <div class="box-content row box-dropdown align-left p-absolute" [ngClass]="{ 'collapse': !isCollapsed }">
                        <div class="col-md-12">
                            <div class="row" *ngFor="let range of ranges">
                                <div class="col-md-12">
                                    <span id="date_range" (click)="datePeriodeClick(range)" [ngClass]="{'period-option-active': selectedRange == range}" class="period-option cursor-pointer">Les {{range}} derniers jours</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="date-perso">Personnaliser :</span>
                                </div>
                            </div>
                            <div class="row date-div">
                                <div class="col-4 p-0">
                                    <input type="text" class="form-control" name="dashDateMin" id="dashDateMin" #dashDateMin="ngModel"
                                           bsDatepicker size="25" placement="bottom" [(ngModel)]="startDate" [maxDate]="endDate"
                                           placeholder="Choisir une date" (bsValueChange)="setRetourMaxDate($event)"
                                           (change)="setRetourMaxDate($event);onChangeInputStart($event)" (blur)="setRetourMaxDate($event)" autocomplete="off"
                                           [bsConfig]="{ containerClass: 'theme-kiss', showWeekNumbers: false }" />
                                    <careweb-error-messages [field]="dashDateMin" [touched]="true">
                                    </careweb-error-messages>
                                </div>
                                <div class="col-2 p-0">
                                    <button type="button" id="button_a" class="btn btn-disabled p-0 w-100 border-radius-0" disabled="true">
                                        à
                                    </button>
                                </div>
                                <div class="col-4 p-0">
                                    <input type="text" class="form-control border-radius-0" name="dashDateMax" id="dashDateMax" #dashDateMax="ngModel"
                                           bsDatepicker size="25" placement="bottom" [bsConfig]="{ containerClass: 'theme-kiss', showWeekNumbers: false }"
                                           placeholder="Choisir une date" [(ngModel)]="endDate" [minDate]="startDate" (change)="onChangeInputEnd($event)"
                                           [maxDate]="currentDate" />
                                    <careweb-error-messages [field]="dashDateMax" [touched]="true">
                                    </careweb-error-messages>
                                </div>
                                <div class="col-2 p-0">
                                    <button type="button" id="date_ok" class="btn btn-primary p-0 w-100" (click)="persoFilter()">
                                        OK
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                      <span class="spanError" *ngIf="!isValidDate">
                        Veuillez choisir des dates de début et de fin correctes
                      </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      <!--- INDICATEURS HAUT --->
      <div class="row">
        <div class="col-lg-9">
          <a class="link-box">
            <div class="box-content box-content-default pointer-cursor" id="voir_FSE" (click)="voirFseEnvoyes()">
              <div class="title">
                <div class="pb-2">FSE</div>
                <div class="subtitle text-primary">Envoyées sur la période</div>
                <div class="row" style="margin-top:40px">
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Volume</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{fseVolume}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Montant</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{fseMontant | number:'':'fr-FR' }}€ </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-right">
                <div class="col-md-12 mt-2">
                  <a id="voir" class="more">Voir</a>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3">
          <a class="link-box">
            <div class="box-content box-content-default pointer-cursor" id="voir_arl_negatif" (click)="voirArlNegatif()">
              <div class="title">
                <div class="pb-2">ARL</div>
                <div class="subtitle text-danger">Négatifs</div>
                <div class="row" style="margin-top:28px">
                  <div class="number text-responsive">{{arlNegatifs}}</div>
                </div>
                <div class="row" style="margin-top:28px"></div>
              </div>
              <div class="row align-right">
                <div class="col-md-12 mt-2">
                  <a id="voir_1" class="more">Voir</a>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <a class="link-box ">
            <div class="box-content box-content-default pointer-cursor" id="voir_rembourssement_RO" (click)="voirRembouressementRO()">
              <div class="title">
                <div class="pb-2">Remboursements</div>
                <div class="subtitle text-primary">AMO</div>
                <div class="row" style="margin-top:40px">
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Volume</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{rembRoVolume}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Montant</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{rembRoMontant  | number:'':'fr-FR'}}€</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-right">
                <div class="col-md-12 mt-2">
                  <a id="voir_2" class="more">Voir</a>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xl-3 col-lg-6">
          <a class="link-box">
            <div class="box-content box-content-default pointer-cursor" id="voir_rembourssement_RC" (click)="voirRembouressementRC()">
              <div class="title">
                <div class="pb-2">Remboursements</div>
                <div class="subtitle text-primary">AMC</div>
                <div class="row" style="margin-top:40px">
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Volume</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{rembRcVolume}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Montant</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{rembRcMontant | number:'':'fr-FR'}}€</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-right">
                <div class="col-md-12 mt-2">
                  <a id="voir_3" class="more">Voir</a>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xl-3 col-lg-6">
          <a class="link-box">
            <div class="box-content box-content-default pointer-cursor" id="voir_facture_impayeRO" (click)="voirFactureImpayeRO()">
              <div class="title">
                <div class="pb-2">Factures en retard (8j)</div>
                <div class="subtitle text-danger">AMO</div>
                <div class="row" style="margin-top:40px">
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Volume</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{fseRoImpayeVolume}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Montant</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{fseRoImpayeMontant | number:'':'fr-FR'}}€</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-right">
                <div class="col-md-12 mt-2">
                  <a id="voir_4" class="more">Voir</a>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xl-3 col-lg-6">
          <a class="link-box">
            <div class="box-content box-content-default pointer-cursor" id="voir_facture_impayeRC" (click)="voirFactureImpayeRC()">
              <div class="title">
                <div class="pb-2">Factures en retard (15j)</div>
                <div class="subtitle text-danger">AMC</div>
                <div class="row" style="margin-top:40px">
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Volume</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{fseRcImpayeVolume}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 box-indicator">
                    <div class="row">
                      <div class="col-md-12">Montant</div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="number text-responsive">{{fseRcImpayeMontant  | number:'':'fr-FR'}}€</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-right">
                <div class="col-md-12 mt-2">
                  <a id="voir_5" class="more">Voir</a>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!--- FIN INDICATEURS HAUT  --->
      <!--- RSP/VRT --->
      <div class="row" *ngIf="this.numeroPs!=null">
        <div class="col-md-12">
          <div class="row">
            <!-- Virements -->
            <div class="col-md-6">
              <a class="link-box">
                <div class="box-content box-content-default">
                  <div class="title">
                    <span>Répartition des factures par type d'organisme</span>
                    <canvas id="chartOrga" width="200" style="margin-top:20px">
                      {{chartFactureOrga}}
                    </canvas>
                  </div>
                </div>
              </a>
            </div>
            <!-- RSP -->
            <div class="col-md-6">
              <a class="link-box">
                <div class="box-content box-content-default">
                  <div class="title">
                    <span>Factures transmises par état</span>
                    <canvas id="chartStatus" style="margin-top:20px">
                      {{chartFactureStatus}}
                    </canvas>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
