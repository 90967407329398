import { RspDataRaw } from './rspDataRaw';
import { BankTransactionDataRaw } from './bankTransactionDataRaw';

export class RapprochementDataRaw {
    idRapprochement: number;
    dateRapprochement: Date;
    dateValide: Date;
    valide: number; 
    rapprochementAuto: number;
    label: string;
    notes: string;
    organismeLibelle: string;
    montantTotalRspExacte: number;
    montantTotalRspFrom: number;
    montantTotalRspTo: number;
    montantTotalTrExacte: number;
    montantTotalTrFrom: number;
    montantTotalTrTo: number;
    montantDiff: boolean;
    periode: number;
    montantTotalRsp: number;
    nbRsp:number;
    montantTotalTr: number;
    nbTransactions: number;
    rsps: RspDataRaw[];
    rspIds: number[];
    bankTransactionIds: number[];
    bankTransactions: BankTransactionDataRaw[];
    idRappComptePs: number;
    idPs:number;
    idEbicsPs: number;
    dateComptableTr: Date;
    dateReceptionRsp: Date;
    commentaire: string;
    pointerKeyword: string;
    idOrganisme: string;
    libelleVirement1 : string;
    libelleVirement2: string;
    executionId: number;

}
