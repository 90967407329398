import { AccordionGroupComponent } from './../../../../components/accordion/accordion-group.component';
import { DateFromToFilterComponent } from './../../../../components/date-from-to/date-from-to-filter.component';
import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { DatePipe, formatDate } from '@angular/common';
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { findIndex, isEmpty, uniqBy } from 'lodash-es';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../../../components/ag-grid/ag-grid.utils';
import { ColumnSelectorComponent } from '../../../../components/ag-grid/grid-column-selector.component';
import { ColumnSpec } from '../../../../components/ag-grid/grid-column-spec';
import { OrganismeTypePipe } from '../../../../core/pipes/organisme-type.pipe';
import { AuthService } from '../../../../core/services/auth.service';
import { ConvertDateFormat } from '../../../../core/utilities/convert-date-format.service';
import { FilterItem } from '../../../../data/filters/filter-item';
import { RspFilterRaw } from '../../../../data/filters/rspFilterRaw';
import { GridDataRaw } from '../../../../data/gridDataRaw';
import { PsDelegueDataRaw } from '../../../../data/PsDelegueDataRaw';
import { RspDataRaw } from '../../../../data/rspDataRaw';
import { User } from '../../../../data/userDataRaw';
import { RouteHistoryService } from '../../../../shared/route-history.service';
import { StorageService } from '../../../../shared/storage-service';
import { PsService } from '../../../ps/ps.service';
//import { PsService } from '../../ps/ps.service';
import { PsDelegueService } from '../../../ps/service/ps-delegue.service';
import { LotsFilterSvc } from '../../lots/lots-filter.service';
import { RspPreviewComponent } from './../rsp-preview.component';
import { RSPService } from './../rsp.service';
import {ExportModalComponent, ExportMode} from "../../../export-modal/export-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { saveAs } from 'file-saver';
import {TransformService} from "../../../../core/services/transform.service";
import {ExportState, State} from "../../../../shared/model/storage.model";
@Component({
	selector: 'careweb-app-rsp',
	templateUrl: './rejet.component.html',
	styleUrls: ['./../rsp.component.scss']
})
export class RejetComponent implements OnInit, OnDestroy {
	params: any;
	initHidden = true;
	showFilter = false;
	gridHelper: AgGridHelper;
	filters = new RspFilterRaw();
	filterList: FilterItem[];
	rspDataRaw: RspDataRaw;
	datePipe: DatePipe;
	currentUser: User;
	showLibelleRejet = true;
	@ViewChild(ColumnSelectorComponent, { static: true })
	columnSelector: ColumnSelectorComponent;
	currentOct: any;
	psDelegueListFilter = new Array<PsDelegueDataRaw>();
	totalResultsHidden = false;
	private openedModal: BsModalRef;
	rowCount = 0;
	exportableColumns = [
		'numeroAdeli',
		'numLot',
		'numFacture',
		'montantFactureRoAsNumber',
		'montantRoAsNumber',
		'montantFactureRcAsNumber',
		'montantRcAsNumber',
		'dateReception',
		'idOrganisme',
		'libelleOrganisme',
		'typeOrganisme',
		'telephoneOrganisme',
		'designationStatusRsp',
		'libelleRejet'
	];
	gridDataRaw: GridDataRaw;

	@ViewChild('dateRsp') dateRsp: DateFromToFilterComponent;
	@ViewChild('dateRspAccordion') dateRspAccordion: AccordionGroupComponent;

	@ViewChild('dateLot') dateLot: DateFromToFilterComponent;
	@ViewChild('dateLotAccordion') dateLotAccordion: AccordionGroupComponent;

	isDateRspRangeValid = true;
	isDateLotRangeValid = true;
	export: boolean = false;
	exportState: ExportState;

	constructor(
		private rspSvc: RSPService,
		private router: Router,
		private authSvc: AuthService,
		private filterServiceSvc: LotsFilterSvc,
		private psSVC: PsService,
		private psDelegueSVC: PsDelegueService,
		private convertFormatDate: ConvertDateFormat,
		private routeHistory: RouteHistoryService,
		private storageService: StorageService,
		private organismeTypePipe: OrganismeTypePipe,
		private modalService: BsModalService,
		private transformService: TransformService
	) {
		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('rspPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('rejet', this.gridDataRaw.size, 10);
		this.rspDataRaw = new RspDataRaw();
		this.convertFormatDate = new ConvertDateFormat();
	}

	ngOnInit() {
		// verify previous url if not details remove session currentPage
		if (
			this.routeHistory?.getPreviousUrl()?.indexOf('rsp-details') === -1 &&
			this.routeHistory?.getPreviousUrl()?.indexOf('ps-details') === -1
		) {
			sessionStorage['rspCurrentPage'] = null;
		}

		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		// Initiate date pipe format
		this.datePipe = new DatePipe('fr-FR');
		// Init filters
		const parsedFilter = JSON.parse(sessionStorage.getItem('rejetFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
			// Convert String dates to Dates format
			this.filters.dateReceptionLotFrom
				? (this.filters.dateReceptionLotFrom = new Date(parsedFilter.dateReceptionLotFrom))
				: '';
			this.filters.dateReceptionLotTo
				? (this.filters.dateReceptionLotTo = new Date(parsedFilter.dateReceptionLotTo))
				: '';
			this.filters.dateReceptionLotExacte
				? (this.filters.dateReceptionLotExacte = new Date(parsedFilter.dateReceptionLotExacte))
				: '';
			this.filters.dateReceptionRspFrom
				? (this.filters.dateReceptionRspFrom = new Date(parsedFilter.dateReceptionRspFrom))
				: '';
			this.filters.dateReceptionRspTo
				? (this.filters.dateReceptionRspTo = new Date(parsedFilter.dateReceptionRspTo))
				: '';
			this.filters.dateReceptionRspExacte
				? (this.filters.dateReceptionRspExacte = new Date(parsedFilter.dateReceptionRspExacte))
				: '';
			//this.showLibelleRejet = this.filters.statutRsp == '1';
		} else {
			this.filterList = [new FilterItem()];
		}

		// Apply numero Ps on the filter if exist
		const numeroPsLocalStorage = localStorage.getItem('numeroPs');
		if (!isEmpty(numeroPsLocalStorage)) {
			if(this.filters.numeroPs && this.filters.numeroPs != numeroPsLocalStorage) {
				this.filters =  new RspFilterRaw();
			}
			this.filters.numeroPs = numeroPsLocalStorage;
			sessionStorage['rejetFilter'] = JSON.stringify(this.filters);
		}
		// Apply numero Ps supervised on the filter if exist
		var numeroPsSupervisedLocalStorage = localStorage.getItem('supervised_ps');
		if (!isEmpty(numeroPsSupervisedLocalStorage)) {
			if (numeroPsSupervisedLocalStorage === '-1') {
				this.filters.numeroPScomboBox = null;
			}
			else {
				numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
				if (this.filters.numeroPScomboBox && this.filters.numeroPScomboBox != numeroPsSupervisedLocalStorage) {
					this.filters = new RspFilterRaw();
				}
				this.filters.numeroPScomboBox = numeroPsSupervisedLocalStorage;
				sessionStorage['rejetFilter'] = JSON.stringify(this.filters);
			}
		}

		// Initiate AG grid
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			paginationPageSize: this.gridDataRaw.size,
			infiniteInitialRowCount: 1,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			enableCellTextSelection: true,
			onRowClicked: (params) => this.onRowClicked(params),
			getRowClass: (params) => this.stylePreviouslyClickedRow(params),
			onGridReady: (params) => this.onGridReady(params),
			onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onColumnMoved: (params) => this.onColumnChanged(params),
			onColumnVisible: (params) => this.onColumnChanged(params)
		};

		// load combobox of numero PS if user is PS
		if (!this.isAuthorized()) {
			this.loadComboBoxFilterNumeroPS_Supervise();
		}
		this.rspSvc.fromRsp = false;
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);

	}
	setColumnOrder(width:any) {
		const columnDefs = this.getColumnDefs();
		const columnApi = this.gridHelper.gridColumnApi;
		const originalColumnOrder = this.getColumnDefs().map(column => column.field);
		if(width<=2140){
			const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'options');
			if (optionsColumnIndex !== -1) {
				columnDefs.splice(0, 0, columnDefs.splice(optionsColumnIndex, 1)[0]);
				columnApi.moveColumn('options', 0);
			}
		}else {
			this.gridHelper.gridApi.sizeColumnsToFit();
			originalColumnOrder.forEach((field, index) => {
				columnApi.moveColumn(field, index);
			});
		}
	}
	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onGridReady(params) {
		this.initFilter();
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.setColumnOrder(window.innerWidth);
		this.initColumnsDisplay();
		let parsedFilter = JSON.parse(sessionStorage.getItem('rejetFilter'));
		if (parsedFilter) {
			Object.keys(parsedFilter).forEach(
				(key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
			);
		}
		if (!isEmpty(parsedFilter)) {
			this.launchSearch();
		} else {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					// Connect By Admin Or Hotline
					if (!ctx.isAuthorized()) {
						// Connect By PS
						const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
						ctx.rspDataRaw = new RspDataRaw();
						ctx.rspDataRaw.statutRsp ='1';
						ctx.rspSvc
							.searchRspsByIdPsWithDelegues(
								{
									page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
									size: ctx.gridHelper.gridApi.paginationGetPageSize(),
									idPs: userConnected.idPs,
									sorts
								},
								ctx.rspDataRaw
							)
							.subscribe(
								(data) => {
									ctx.rowCount = data.totalElements;
									ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
									paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
									const pageN = Number.parseInt(sessionStorage.getItem('rspCurrentPage'));
									if (
										!isNullOrUndefined(pageN) &&
										pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
										pageN > 0
									) {
										ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
									}
									sessionStorage['rspCurrentPage'] = null;
									ctx.totalResultsHidden = true;
								},
								() => paramsRows.fail()
							);
					}
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(ctx.isAuthorized() ? null : dataSource);
			// this.fillFilter();
		}
	}

	existGroupNumeroPS() {
		return this.isAuthorized() || this.psDelegueListFilter.length !== 0;
	}

	loadComboBoxFilterNumeroPS_Supervise() {
		const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
		if (!isEmpty(userConnected)) {
			if (!isNullOrUndefined(userConnected.idPs)) {
				this.psDelegueSVC.getPsDelegueByIdPs(userConnected.idPs).subscribe((data) => {
					if (!isNullOrUndefined(data) && !isEmpty(data)) {
						this.psDelegueListFilter = data;
						const psCurrent = new PsDelegueDataRaw();
						psCurrent.idPsDelegue = this.psDelegueListFilter[0].idPsAssocie;
						psCurrent.numeroPsDelegue = this.psDelegueListFilter[0].numeroPsAssocie;
						psCurrent.nomPsDelegue = this.psDelegueListFilter[0].nomPsAssocie;
						this.psDelegueListFilter.push(psCurrent);
					}
					isNullOrUndefined(this.gridHelper.gridColumnApi) ? null : this.gridHelper.gridColumnApi.setColumnVisible('numeroAdeli', this.existGroupNumeroPS());
				});
			}
		}
	}

	onRowClicked(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			sessionStorage.previouslyClickedRspCode = params.node.data.codeRsp;
			this.router.navigate(['/rsp-details', params.data.codeRsp]);
		}
	}

	stylePreviouslyClickedRow(params: any) {
		let rowNode = params.node;
		if (!isNullOrUndefined(rowNode.data)) {
			let previouslyClickedRow = sessionStorage.getItem('previouslyClickedRspCode');
			if (rowNode.data.codeRsp === Number(previouslyClickedRow)) {
				return 'ag-row-selected';
			}
		}
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		this.initColumnsDisplay();
		//this.resetAllFilter();
	}

	exportExcel() {
		const fromRsp = false;
		const columns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);
		if (this.rowCount > 0 && this.rowCount <= 1000) {
			this.exportToExcel(columns,fromRsp);
		} else if (this.rowCount > 1000) {
			this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
					nbrElement: this.rowCount
				}, class: 'modal-lg'});
			this.openedModal.content.exportEvent.subscribe((e: any) => {
				if (e.action === ExportMode.EXPORT) {
					// this.exportFileXlsx();
					this.exportToExcel(columns,fromRsp);
				}
				if (e.action === ExportMode.EMAIL) {
					// this.exportFileXlsx();
					this.exportByEmail(columns,fromRsp);
				}
			});
		}
	}

	exportExcelClient() {
		const ctx = this;
		let exportableColumns = this.gridHelper.gridColumnApi.getAllDisplayedColumns().map((c:any) => c.colId).filter(col => col != 'idFacture');
		if (!this.isAuthorized()) {
			// Le compte PS ne doit pas voir la colonne du numéro PS
			const nonExportableColumns = ['numeroAdeli'];
			exportableColumns = exportableColumns.filter((item) => nonExportableColumns.indexOf(item) === -1);
		}

		const params = {
			skipHeader: false,
			columnGroups: true,
			skipFooters: false,
			skipGroups: true,
			skipPinnedTop: false,
			skipPinnedBottom: false,
			allColumns: true,
			onlySelected: false,
			fileName: 'Liste_Rejets',
			sheetName: 'Liste_Rejets',
			columnKeys: exportableColumns,
			processCellCallback: function (params) {
				if (
					params.column.colDef.field === 'montantFactureRoAsNumber' ||
					params.column.colDef.field === 'montantRoAsNumber' ||
					params.column.colDef.field === 'montantFactureRcAsNumber' ||
					params.column.colDef.field === 'montantRcAsNumber'
				) {
					params.value = ctx.formatCurrency({
						data: true,
						value: params.value
					});
				} else if (params.column.colDef.field === 'dateReception') {
					params.value = ctx.formatDate({
						value: params.value
					});
				} else if(params.column.colDef.field === 'typeOrganisme') {
					params.value = ctx.organismeTypePipe.transform(params.value)
				} else if(params.column.colDef.field === 'libelleVirement1' || params.column.colDef.field === 'libelleVirement2')  {
					if(params.value) {
						params.value = String(params.value) + ';';
					} else {
						params.value = '';
					}
				}
				return params.value;
			},
			processRowGroupCallback: (params) => this.rowGroupCallback(params)
		};
		this.gridHelper.paginationPageSize = this.gridDataRaw.size;
		this.gridHelper.gridApi.exportDataAsExcel(params);
	}

	exportByEmail(columns,fromRsp){
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
		this.rspSvc.exportRspsByEmail(
			{
				page: 0,
				size: this.rowCount,
				export: false,
				sorts
			},
			this.rspDataRaw,
			columns,
			fromRsp
		).subscribe(()=> {
			// when email received
		});
	}
	exportToExcel(columns,fromRsp){
		this.export = true;
		this.exportState = {
			message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
			state: State.INPROGRESS
		}
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
		this.rspSvc.exportRsps(
			{
				page: 0,
				size: this.rowCount,
				export: false,
				sorts
			},
			this.rspDataRaw,
			columns,
			fromRsp
		).subscribe((response)=> {
			saveAs(response.file, response.fileName);
			this.exportState = {
				message : "Votre fichier a été téléchargé avec succès.",
				state: State.SUCCESS
			}
		},() => {
			this.exportState = {
				message : "Le téléchargement de votre fichier a rencontré un problème.",
				state: State.FAILED
			}
		});
	}
	closeAlert() {
		this.export = false;
	}
	rowGroupCallback(params) {
		return params.node.key;
	}

	refresh(): boolean {
		return false;
	}

	resetAllFilter(): void {
		this.dateLot.isDateRangeValid = true;
		this.dateRsp.isDateRangeValid = true;
		this.isDateLotRangeValid = true;
		this.isDateRspRangeValid = true;

		if (!this.isAuthorized()) {
			this.initFilter();
			this.filters = new RspFilterRaw();
			this.launchSearch();
			this.filterServiceSvc.reset();
		} else {
			this.filters = new RspFilterRaw();
			this.rowCount = 0;
			this.filterServiceSvc.reset();
			this.updateFilterList(this.filters);
			this.clearGridFromData();
		}
		sessionStorage.removeItem('rejetFilter');
	}

	onKeyDown(event) {
		this.launchSearch();
	}

	public onChange(event) {
		if (!this.showLibelleRejet) {
			this.filters.libelleRejet = '';
		} else {
		}
		this.initColumnsDisplay();
	}

	launchSearch(): void {
		this.initColumnsDisplay();
		const ctx = this;
		ctx.totalResultsHidden = true;
		if (this.isFormValid()) {
			this.rspDataRaw.nomPS = this.filters.nomTitulaire || null;
			this.rspDataRaw.raisonSocialEtablissement = this.filters.raisonSocialEtablissement || null;
			this.rspDataRaw.numeroAdeli = this.isAuthorized()
				? this.filters.numeroPs ? this.filters.numeroPs : null
				: this.filters.numeroPScomboBox ? this.filters.numeroPScomboBox : null;
			this.rspDataRaw.dateDerniereTransmission = !isNullOrUndefined(this.filters.dateDerniereTransmission)
				? formatDate(this.filters.dateDerniereTransmission, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'fr-FR')
				: null;
			this.filters.numLot = this.formatNumLot(this.filters.numLot); //@TODO: to put in utils service
			this.rspDataRaw.numLot = this.filters.numLot || null;
			this.filters.numFacture = this.filters.numFacture ? this.filters.numFacture.trim() : null;
			this.rspDataRaw.numFacture = this.filters.numFacture || null;
			/* 			this.rspDataRaw.dateReceptionPartitionFrom = !isNullOrUndefined(this.filters.dateReceptionLotFrom)
				? formatDate(this.filters.dateReceptionLotFrom, "yyyy-MM-dd'T'00:00:00.SSS", 'fr-FR')
				: null;
			this.rspDataRaw.dateReceptionPartitionTo = !isNullOrUndefined(this.filters.dateReceptionLotTo)
				? formatDate(this.filters.dateReceptionLotTo, "yyyy-MM-dd'T'23:59:59.SSS", 'fr-FR')
				: null; */
			this.rspDataRaw.dateReceptionPartitionFrom = this.convertFormatDate.getFormatDateFr(
				this.filters.dateReceptionLotFrom
			);
			this.rspDataRaw.dateReceptionPartitionTo = this.convertFormatDate.getFormatDateFr(
				this.filters.dateReceptionLotTo
			);
			this.rspDataRaw.dateReceptionPartitionExacte = this.convertFormatDate.getFormatDateFr(
				this.filters.dateReceptionLotExacte
			);

			this.rspDataRaw.idOrganisme = this.filters.codeOrganisme || null;
			this.filters.libelleOrganisme = this.filters.libelleOrganisme ? this.filters.libelleOrganisme.trim() : null;
			this.rspDataRaw.libelleOrganisme = this.filters.libelleOrganisme || null;
			this.rspDataRaw.libelleVirement1 = this.filters.libelleVirement1 ? this.filters.libelleVirement1.toUpperCase() : null;
			this.rspDataRaw.libelleVirement2 = this.filters.libelleVirement1 ? this.filters.libelleVirement1.toUpperCase() : null;
			// RSP reçus
			this.rspDataRaw.dateReceptionFrom = this.convertFormatDate.getFormatDateFr(
				this.filters.dateReceptionRspFrom
			);
			this.rspDataRaw.dateReceptionTo = this.convertFormatDate.getFormatDateFr(this.filters.dateReceptionRspTo);
			this.rspDataRaw.dateReceptionExacte = this.convertFormatDate.getFormatDateFr(this.filters.dateReceptionRspExacte);

			this.rspDataRaw.statutRsp = '1';
			this.filters.libelleRejet = this.filters.libelleRejet ? this.filters.libelleRejet.trim() : null;
			this.rspDataRaw.libelleRejet = this.filters.libelleRejet || null;
			this.rspDataRaw.rapprochement = isNullOrUndefined(this.filters.rapprochement)
				? null
				: this.filters.rapprochement;
			this.rspDataRaw.rapproBancaire = isNullOrUndefined(this.filters.rapproBancaire)
				? null
				: this.filters.rapproBancaire;
			this.rspDataRaw.avecVirement = isNullOrUndefined(this.filters.avecVirement)
				? null
				: this.filters.avecVirement;

			this.updateFilterList(this.filters);
			Object.keys(this.rspDataRaw).forEach((key) => {
				if (isNullOrUndefined(this.rspDataRaw[key])) {
					delete this.rspDataRaw[key];
				}
			});
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					// Connect By Admin Or Hotline
					if (ctx.isAuthorized()) {
						ctx.rspDataRaw.idOct = ctx.currentOct?.idOct;
						ctx.rspSvc
							.getRspFilteredList(
								{
									page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
									size: ctx.gridHelper.gridApi.paginationGetPageSize(),
									sorts
								},
								ctx.rspDataRaw
							)
							.subscribe(
								(data) => {
									ctx.rowCount = data.totalElements;
									ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
									paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
									const pageN = Number.parseInt(sessionStorage.getItem('rspCurrentPage'));
									if (
										!isNullOrUndefined(pageN) &&
										pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
										pageN > 0
									) {
										ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
									}
									sessionStorage['rspCurrentPage'] = null;
								},
								() => paramsRows.fail()
							);
					} else {
						// Connect By PS
						ctx.rspDataRaw.idOct = null;
						const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
						ctx.rspSvc
							.searchRspsByIdPsWithDelegues(
								{
									page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
									size: ctx.gridHelper.gridApi.paginationGetPageSize(),
									idPs: userConnected.idPs,
									sorts
								},
								ctx.rspDataRaw
							)
							.subscribe(
								(data) => {
									ctx.rowCount = data.totalElements;
									ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
									paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
									const pageN = Number.parseInt(sessionStorage.getItem('rspCurrentPage'));
									if (
										!isNullOrUndefined(pageN) &&
										pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
										pageN > 0
									) {
										ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
									}
									sessionStorage['rspCurrentPage'] = null;
								},
								() => paramsRows.fail()
							);
					}
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			//this.showLibelleRejet = this.filters.statutRsp == '1';
			sessionStorage['rejetFilter'] = JSON.stringify(this.filters);
		} else {
		}
	}

	formatNumLot(numLot) {
		if (!isNullOrUndefined(numLot)) {
			numLot = numLot.trim().toUpperCase();
			if (numLot.length == 3) {
				return numLot;
			} else if (numLot.length == 2) {
				return '0' + numLot;
			} else if (numLot.length == 1) {
				return '00' + numLot;
			}
		} else {
			return null;
		}
	}

	isFormValid(): boolean {
		let verif = true;
		if (this.filters.dateReceptionRspFrom && this.filters.dateReceptionRspTo) {
			if ((this.filters.dateReceptionRspFrom.getTime() > this.filters.dateReceptionRspTo.getTime()) || !this.isDateRspRangeValid) {
				verif = false;
				this.dateRspAccordion.expansionPannel.open();
				this.dateRsp.showTooltipDebut();
				this.dateRsp.showTooltipFin();
			} else {
				this.dateRsp.hideTooltlipDebut();
				this.dateRsp.hideTooltlipFin();
			}
		}
		if (this.filters.dateReceptionLotFrom && this.filters.dateReceptionLotTo) {
			if ((this.filters.dateReceptionLotFrom.getTime() > this.filters.dateReceptionLotTo.getTime()) || !this.isDateLotRangeValid) {
				verif = false;
				this.dateLotAccordion.expansionPannel.open();
				this.dateLot.showTooltipDebut();
				this.dateLot.showTooltipFin();
			} else {
				this.dateLot.hideTooltlipDebut();
				this.dateLot.hideTooltlipFin();
			}
		}
		return verif;
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'N° de PS',
				field: 'numeroAdeli',
				minWidth: 100,
				maxWidth: 150,
				lockVisible: true,
				cellClass: (params) => this.styleLink(params),
				hide: !this.existGroupNumeroPS(),
				onCellClicked: (params) => this.onPsCellClicked(params),
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Lot',
				field: 'numLot',
				minWidth: 60,
				maxWidth: 100,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
				headerTooltip: 'Numéro du lot'
			},
			{
				headerName: 'N° de FSE',
				field: 'numFacture',
				minWidth: 100,
				maxWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Libellé organisme',
				field: 'libelleOrganisme',
				minWidth: 250,
				lockVisible: true,
				sortable: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Code organisme',
				field: 'idOrganisme',
				minWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				hide: true
			},
			{
				headerName: 'Libellé du rejet',
				field: 'libelleRejet',
				minWidth: 200,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Montant AMO',
				field: 'montantFactureRoAsNumber',
				valueFormatter: this.formatCurrency,
				minWidth: 150,
				lockVisible: true,
				cellClass: ['font-weight-bold'],
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Montant payé AMO',
				field: 'montantRoAsNumber',
				minWidth: 150,
				lockVisible: true,
				valueFormatter: this.formatCurrency,
				cellClass: ['font-weight-bold'],
				cellStyle: this.gridHelper.centerContent(),
				hide: true,
				sortable: true
			},
			{
				headerName: 'Montant AMC',
				field: 'montantFactureRcAsNumber',
				valueFormatter: this.formatCurrency,
				minWidth: 150,
				lockVisible: true,
				cellClass: ['font-weight-bold'],
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Montant payé AMC',
				field: 'montantRcAsNumber',
				minWidth: 150,
				lockVisible: true,
				valueFormatter: this.formatCurrency,
				cellClass: ['font-weight-bold'],
				cellStyle: this.gridHelper.centerContent(),
				hide: true,
				sortable: true
			},
			{
				headerName: 'Date de réception RSP',
				field: 'dateReception',
				minWidth: 150,
				maxWidth: 200,
				valueFormatter: this.formatDate,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Type organisme',
				field: 'typeOrganisme',
				valueFormatter: (params) => this.organismeTypePipe.transform(params.value),
				minWidth: 150,
				maxWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				hide: true
			},
			{
				headerName: 'Téléphone organisme ',
				field: 'telephoneOrganisme',
				minWidth: 100,
				maxWidth: 200,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
				hide: true
			},
			{
				headerName: 'Libellé de virement 1',
				field: 'libelleVirement1',
				minWidth: 250,
				sortable: true,
				hide: true,
				cellStyle: this.gridHelper.centerContent(),
			},
			{
				headerName: 'Libellé de virement 2',
				field: 'libelleVirement2',
				minWidth: 250,
				sortable: true,
				hide: true,
				cellStyle: this.gridHelper.centerContent(),
			},
			{
				headerName: '',
				field: 'idFacture',
				minWidth: 50,
				maxWidth: 50,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: RspPreviewComponent,
				hide: true
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 50,
				maxWidth: 50,
				lockVisible: true,
				cellRenderer: RspPreviewComponent,
				cellStyle: this.gridHelper.centerContent(),
				cellRendererParams: {
					iconClass: 'fa fa-eye',
					redirect: 'rspDetails'
				}
			}
		];
	}

	styleLink(params) {
		let previouslyClickedRow = sessionStorage.getItem('previouslyClickedRspCode');

		if (!isNullOrUndefined(params.data) && params.data.codeRsp === Number(previouslyClickedRow)) {
			return 'link-in-selected-row';
		} else {
			return 'link-in-not-selected-rows';
		}
	}

	onPsCellClicked(params) {
		var ctx = this;
		if (this.isAuthorized() && !this.gridHelper.isCellTextSelected()) {
			ctx.psSVC
				.getPsByNumAdeli({
					numAdeli: params.value
				})
				.subscribe((data) => {
					this.router.navigate(['/ps-details', data[0].idPs]);
				});
		}
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	hasRapproBancaireAccess() {
		if (!this.isAuthorized()) {
			if (localStorage.getItem('current_ps') != null) {
				const currentPs = JSON.parse(localStorage.getItem('current_ps'));
				const rappComptePs = currentPs.rappComptePs;
				const psEbics = currentPs.psEbics;
				if(rappComptePs && currentPs.typeRappro == 'OXLIN'){
					return rappComptePs.actif;
				}
				else if(psEbics && currentPs.typeRappro == 'EBICS'){
					return this.isEbicsActif(psEbics);
				}
				else{
					return false;
				}
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
	isEbicsActif(ebicsPs) {
		return ebicsPs.filter((e) => e.actif)?.length > 0;
	}

	formatDate = (params): string => {
		return this.datePipe.transform(params.value, 'short');
	};

	formatDateForTags = (params): string => {
		if (params.value !== null && params.value != 'Invalid Date') {
			return this.datePipe.transform(params.value, 'dd/MM/yyyy');
		}
	};

	formatCurrency = (params): string => {
		if (params.data) {
			if (params.value) {
				return params.value / 100 + ' €';
			} else {
				return '0.00 €';
			}
		}
	};

	removeFilter(id) {
		const ctx = this;
		delete ctx.filters[id];
		let currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		/* 		if (id === 'numeroPs') {
			this.psSVC.removeLocalStorageOfNumeroPS();
		} */
		if ((Array.isArray(ctx.filterList) && ctx.filterList.length) || !ctx.isAuthorized()) {
			this.fillFilterObjectFromTagsOnly();
			ctx.launchSearch();
		} else {
			ctx.clearGridFromData();
			this.rowCount = 0;
		}
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new RspFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}

	initFilter() {
		// Liste des filtres
		this.resetFilter();
		this.updateFilterList(this.filters);
	}

	resetFilter() {
		this.filterServiceSvc.reset();
		this.rspDataRaw.statutRsp = '1';
	}

	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (
				!isNullOrUndefined(filters[key]) &&
				filters[key] !== '' &&
				key !== 'raisonSocialEtablissement' &&
				key !== 'nomTitulaire' &&
				key !== 'idPs'
			) {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id !== null;
		});
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		const ctx = this;
		switch (key) {
			case 'numeroPScomboBox':
			case 'numeroPs':
				translatedKey = 'N° de PS';
				break;
			case 'numLot':
				translatedKey = 'N° du lot';
				break;
			case 'numFacture':
				translatedKey = 'N° de FSE';
				break;
			case 'dateReceptionLotFrom':
				translatedKey = 'Lots reçus après le';
				formattedValue = ctx.formatDateForTags({ value: value });
				break;
			case 'dateReceptionLotTo':
				translatedKey = 'Lots reçus avant le';
				formattedValue = ctx.formatDateForTags({ value: value });
				break;
			case 'dateReceptionLotExacte':
				translatedKey = 'Lots reçus le';
				formattedValue = ctx.formatDateForTags({ value: value });
				break;
			case 'codeOrganisme':
				translatedKey = 'Code organisme émetteur';
				break;
			case 'libelleOrganisme':
				translatedKey = 'Libellé organisme émetteur';
				break;
			case 'libelleVirement1':
				translatedKey = 'Libellé de virement';
				break;
			case 'dateReceptionRspFrom':
				translatedKey = 'RSP reçus après le';
				formattedValue = ctx.formatDateForTags({ value: value });
				break;
			case 'dateReceptionRspTo':
				translatedKey = 'RSP reçus avant le';
				formattedValue = ctx.formatDateForTags({ value: value });
				break;
			case 'dateReceptionRspExacte':
				translatedKey = 'RSP reçus le';
				formattedValue = ctx.formatDateForTags({ value: value });
				break;
			case 'libelleRejet':
				translatedKey = 'Libellé du rejet';
				break;
			case 'rapprochement':
				translatedKey = 'Rapprochement';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			case 'rapproBancaire':
				translatedKey = 'Rappro. bancaire';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			case 'avecVirement':
				translatedKey = 'Avec Virement';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			default:
				break;
		}
		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	ngOnDestroy() {
		sessionStorage['rejetFilter'] = JSON.stringify(this.filters);
		sessionStorage['rspCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	selectColumnEvent(col: ColumnSpec) {
		this.gridHelper.gridColumnApi.setColumnVisible(col.id, true);
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.addColumn(col.id);
	}

	deselectColumnEvent(col: ColumnSpec) {
		this.gridHelper.gridColumnApi.setColumnVisible(col.id, false);
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.removeColumn(col.id);
	}

	initColumnsDisplay() {
		const allColumns = this.gridHelper.gridColumnApi.getColumns();
		const selectedColumns = this.gridHelper.gridColumnApi.getAllDisplayedColumns();
		this.columnSelector.setItems(this.getColumnSpecs(allColumns));
		this.columnSelector.setSelectedItems(this.getColumnSpecs(selectedColumns));
	}

	removeColumn(columnName: string) {
		const index = this.exportableColumns.indexOf(columnName);
		if (index > -1) {
			this.exportableColumns.splice(index, 1);
		}
	}

	addColumn(columnName: string) {
		if (this.exportableColumns.indexOf(columnName) === -1) {
			this.exportableColumns.push(columnName);
		}
	}

	private getColumnSpecs(columns: any[]): ColumnSpec[] {
		let allowedColumns = [
			'numeroAdeli',
			'montantRoAsNumber',
			'montantRcAsNumber',
			'dateReception',
			'idOrganisme',
			'typeOrganisme',
			'telephoneOrganisme',
			'libelleRejet',
			'libelleVirement1',
			'libelleVirement2',
			'libelleOrganisme'
		];
		/*if (!this.showLibelleRejet) {
			allowedColumns = allowedColumns.filter((item) => item !== 'libelleRejet');
			this.gridHelper.gridOptions.columnApi.setColumnVisible('libelleRejet', false);
		} else {
			allowedColumns = allowedColumns;
			this.gridHelper.gridOptions.columnApi.setColumnVisible('libelleRejet', true);
		}*/

		if (!this.isAuthorized()) {
			// Le compte PS ne doit pas voir les 3 premières colonnes
			allowedColumns = allowedColumns.slice(1);
		}

		return columns
			.filter(
				(cd) => cd.colDef.headerName && cd.colDef.headerName.length > 0 && allowedColumns.indexOf(cd.colId) > -1
			)
			.map((cd) => {
				return {
					id: cd.colDef.field,
					name: cd.colDef.headerName,
					label: cd.colDef.headerName
				};
			});
	}

	// disable appliquer les filtres button
	disableSearch() {
		if (
			this.isAuthorized() &&
			Object.values(this.filters).every((o) => o === null || o === '' || o === undefined)
		) {
			return true;
		} else {
			return false;
		}
	}

	//clear grid
	clearGridFromData() {
		this.totalResultsHidden = false;
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.rowCount = 0;
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		if (this.isAuthorized() && this.rowCount != 0){
			this.launchSearch();
		} else if(!this.isAuthorized()){
			this.launchSearch();
		}
		this.gridHelper.saveGridStatePrefix();
	}

	onColumnChanged(params: any): void {
	//	this.gridHelper.gridColumnApi.setColumnsVisible(["numeroAdeli"], this.existGroupNumeroPS());
		this.gridHelper.saveGridColumnState();
	}

	onChoiceChange($event, type) {
		if ($event.filterChoice === "EXACTE") {
			this.filters[type + 'From'] = null;
			this.filters[type + 'To'] = null;
		} else {
			this.filters[type + 'Exacte'] = null;
		}
	}

	checkDateRangeValidity(event, type) {
		if (type === 'dateLot' && this.dateLot?.popDebut && this.dateLot?.popFin) {
			this.isDateLotRangeValid = event;
			this.dateLot.hideTooltlipDebut();
			this.dateLot.hideTooltlipFin();
		}
		if (type === 'dateRsp' && this.dateRsp && this.dateRsp.popDebut && this.dateRsp.popFin) {
			this.isDateRspRangeValid = event;
			this.dateRsp.hideTooltlipDebut();
			this.dateRsp.hideTooltlipFin();
		}
	}
}
