import {Component, HostListener, OnInit, ViewChild} from "@angular/core";
import {AgGridHelper} from "../../components/ag-grid/ag-grid-helper";
import {DateFromToFilterComponent} from "../../components/date-from-to/date-from-to-filter.component";
import {GridDataRaw} from "../../data/gridDataRaw";
import {SuiviEbicsDataRaw} from "../../data/suiviEbicsDataRaw";
import {FilterItem} from "../../data/filters/filter-item";
import {DatePipe} from "@angular/common";
import {isEmpty, uniqBy} from "lodash-es";
import {ColDef, IServerSideDatasource, IServerSideGetRowsParams} from "ag-grid-enterprise";
import {gridConvertSort} from "../../components/ag-grid/ag-grid.utils";
import {isNullOrUndefined} from "util";
import {ConvertDateFormat} from "../../core/utilities/convert-date-format.service";
import {StorageService} from "../../shared/storage-service";
import {EbicsService} from "../ps/service/ebics.service";
import * as _ from "lodash-es";
import {AccordionGroupComponent} from "../../components/accordion/accordion-group.component";
import {SuiviEbicsPreviewComponent} from "./suivi-ebics-preview.component";

@Component({
    selector: 'app-suivi-ebics',
    templateUrl: './suivi-ebics.component.html',
    styleUrls: ['./suivi-ebics.component.scss']
})
export class SuiviEbicsComponents implements OnInit {
    suiviEbicsGridHelper: AgGridHelper;
    @ViewChild('dateReponse')
    dateReponse: DateFromToFilterComponent;
    @ViewChild('dateAccordion')
    dateAccordion: AccordionGroupComponent;
    gridDataRaw: GridDataRaw;
    suiviEbicsDataRaw: SuiviEbicsDataRaw;
    filterList: FilterItem[];
    filters: SuiviEbicsDataRaw = new SuiviEbicsDataRaw();
    datePipe: DatePipe;
    showFilter = false;
    initHidden = true;
    public rowCount: number = -1;
    sorts: any;
    maxDate: Date;
    isDateRangeValid = true;
    protected filterListTmp: FilterItem[];
    showDetailModal: Boolean = false;
    jsonMessage;

    constructor(private storageService: StorageService,
                private convertFormatDate: ConvertDateFormat,
                private ebicsService: EbicsService) {
        const sessionPageSize = this.storageService.get('suiviEbicsPaginationPageSize', true);
        this.gridDataRaw = new GridDataRaw();
        this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
        this.suiviEbicsGridHelper = new AgGridHelper('suiviEbics', this.gridDataRaw.size, 10);
        this.suiviEbicsDataRaw = new SuiviEbicsDataRaw();
        this.maxDate = new Date();
    }

    ngOnInit() {
        this.datePipe = new DatePipe('fr-FR');
        this.parseFilters();
        this.filters.dateDemarrageExacte = new Date();
        sessionStorage['suiviEbicsFiltre'] = JSON.stringify(this.filters);
        this.initSuiviEbicsGrid();
    }

    parseFilters() {
        const parsedFilter = JSON.parse(sessionStorage.getItem('suiviEbicsFiltre'));
        if (!isEmpty(parsedFilter)) {
            this.filters = Object.assign({}, parsedFilter);
            // Convert String dates to Dates format
            this.filters.dateDemarrageExacte ? (this.filters.dateDemarrageExacte = new Date(parsedFilter.dateDemarrageExacte)) : null;
            this.filters.dateDemarrageFrom ? (this.filters.dateDemarrageFrom = new Date(parsedFilter.dateDemarrageFrom)) : null;
            this.filters.dateDemarrageTo ? (this.filters.dateDemarrageTo = new Date(parsedFilter.dateDemarrageTo)) : null;
            this.filters.hostId ? (this.filters.hostId = parsedFilter.hostId) : null;
            this.filters.ebicsStatut ? (this.filters.ebicsStatut = parsedFilter.ebicsStatut) : null;
            this.filters.erreurCategorie ? (this.filters.erreurCategorie = parsedFilter.erreurCategorie) : null;
            this.filters.erreur ? (this.filters.erreur = parsedFilter.erreur) : null;

        } else {
            this.filterList = [];
        }
    }

    initSuiviEbicsGrid() {
         this.suiviEbicsGridHelper.gridOptions = {
            columnDefs: this.getColumnDefsSuiviEbics(),
            defaultColDef: {
                resizable: true,
                suppressMenu: true
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            suppressServerSideInfiniteScroll: false,
            cacheBlockSize: 10,
            maxBlocksInCache: 0,
            infiniteInitialRowCount: 1,
            pagination: true,
            paginationPageSize: this.gridDataRaw.size,
            paginateChildRows: true,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            enableCellTextSelection: true,
            localeText: this.suiviEbicsGridHelper.getLocaleText(),
            onGridReady: (params) => this.onSuiviEbicsGridReady(params),
            onGridSizeChanged: (params) => this.onGridSizeChanged(params),
        };
    }

    getColumnDefsSuiviEbics(): ColDef[] {
        return [
            {
            headerName: 'Date réponse',
            field: 'dateReponse',
            cellClass: ['text-center'],
            valueFormatter: this.formatDateReponse,
            minWidth: 150,
            maxWidth: 200,
            lockVisible: true,
            sortable: true,
            sort:'desc'
            },{
                headerName: 'Abonnement',
                field: 'bankName',
                cellClass: ['text-center'],
                minWidth: 100,
                maxWidth: 150,
                lockVisible: true,
                sortable: true
            },
            {
                headerName: 'HOST ID',
                field: 'hostId',
                cellClass: ['text-center'],
                minWidth: 100,
                maxWidth: 150,
                lockVisible: true,
                sortable: true
            },
            {
                headerName: 'Statut',
                field: 'ebicsStatut',
                cellClass: ['text-center'],
                minWidth: 100,
                maxWidth: 200,
                lockVisible: true,
                cellRenderer: (params) => this.statutFormat(params),
                sortable: true
            },
            {
                headerName: 'Catégorie d\'erreur',
                field: 'erreurCatgorie',
                cellClass: ['text-center'],
                minWidth: 200,
                lockVisible: true,
                valueFormatter: this.valueFormat,
                sortable: true
            },
            {
                headerName: 'Erreur',
                field: 'erreur',
                cellClass: ['text-center'],
                minWidth: 200,
                lockVisible: true,
                valueFormatter: this.valueFormat,
                sortable: true
            },
            {
                headerName: 'Path',
                field: 'payloadFilePath',
                cellClass: ['text-center'],
                minWidth: 80,
                maxWidth: 80,
                lockVisible: true,
                cellRenderer: SuiviEbicsPreviewComponent,
                cellRendererParams: {
                    iconClass: 'fa fa-print',
                    action: 'load'
                },
                sortable: true
            },
            {
                headerName: '',
                field: 'options',
                minWidth: 80,
                maxWidth: 80,
                cellRenderer: SuiviEbicsPreviewComponent,
                cellRendererParams: {
                    iconClass: 'fa fa-eye',
                    action: 'detail'
                },
                lockVisible: true,
                cellStyle: this.suiviEbicsGridHelper.centerContent(),
                onCellClicked: (params) => this.showModal(params)
            }
        ];
    }
    onGridSizeChanged(params) {
        this.suiviEbicsGridHelper.gridApi.sizeColumnsToFit();
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let width = event.target.innerWidth;
        this.setColumnOrder(width);
    }
    setColumnOrder(width:any) {
        const columnDefs = this.getColumnDefsSuiviEbics();
        if(width<=1000){
            const columnApi = this.suiviEbicsGridHelper.gridColumnApi;
            const payloadFilePathIndex = columnDefs.findIndex(column => column.field === 'payloadFilePath');
            const optionsIndex = columnDefs.findIndex(column => column.field === 'options');
            if (payloadFilePathIndex !== -1 || optionsIndex  !== -1) {
                columnApi.moveColumn('options', 0);
                columnApi.moveColumn('payloadFilePath', 1);
            }
        }else {
            this.suiviEbicsGridHelper.gridApi.sizeColumnsToFit();
            this.suiviEbicsGridHelper.gridApi.setColumnDefs(columnDefs);
        }
    }
    showModal(params) {
        this.jsonMessage = this.parseMessage(params.data.json);
        this.showDetailModal = true;
    }

    objectKeys(obj: any): string[] {
        return Object.keys(obj);
    }

    isObject(value: any): boolean {
        return value && typeof value === 'object' && !Array.isArray(value);
    }
    parseMessage(message: string): any {
        const mainRegex = /(\w+)\s*=\s*(\{.*?\}|[^,]+)(?=, \w+\s*=|}$)/g;
        const result = {};
        let match;

        while ((match = mainRegex.exec(message)) !== null) {
            let value = match[2].trim();
            if (value.startsWith('{') && value.endsWith('}')) {
                value = this.parseProperties(value);
            }
            result[match[1]] = value;
        }

        return result;
    }

    private parseProperties(propertiesString: string): any {
        const propertiesRegex = /(\w+)\s*=\s*(.*?)(?=, \w+\s*=|}$)/g;
        const properties = {};
        let match;

        while ((match = propertiesRegex.exec(propertiesString)) !== null) {
            let value = match[2].trim();
            properties[match[1]] = value;
        }

        return properties;
    }

    statutFormat(params) {
        let statut = params.value
        if (statut === 'ERROR') {
            return "<span class='ui-grid-cell-contents red'>"+statut+"</span>";
        } else if(statut === 'NO_DATA_AVAILABLE') {
            return "<span class='ui-grid-cell-contents orange'>"+statut+"</span>";
        } else {
            return "<span class='ui-grid-cell-contents' container='body'>"+statut +"</span>";
        }
    }

    cancel() {
        this.showDetailModal = false;
        this.suiviEbicsGridHelper.saveGridStatePrefix();
    }

    valueFormat(params) {
        return params.value ? params.value : '-'
    }

    formatDateReponse(params) {
        if (params.value != null && params.value != 'Invalid Date') {
            return params.value;

        } else {
            return '-';
        }
    }

    onSuiviEbicsGridReady(params) {
        this.suiviEbicsGridHelper.gridApi = params.api;
        this.suiviEbicsGridHelper.gridColumnApi = params.columnApi;
        let parsedFilter = JSON.parse(sessionStorage.getItem('suiviEbicsFiltre'));
        if (parsedFilter) {
            Object.keys(parsedFilter).forEach(
                (key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
            );
        }
        this.restoreGrid();
        if (!isEmpty(parsedFilter)) {
            this.launchSearch();
        }else this.clearGridFromData();
    }

    hasChoiceFilter(){
        return this.filters.dateDemarrageExacte ||
            this.filters.dateDemarrageFrom ||
            this.filters.dateDemarrageTo;
    }

    restoreGrid() {
        this.suiviEbicsGridHelper.restoreGridStatePrefix();
        this.gridDataRaw.size = this.suiviEbicsGridHelper.paginationPageSize;
    }
    clearGridFromData() {
        this.rowCount = -1;
        this.suiviEbicsGridHelper.gridApi.setServerSideDatasource(null);
    }

    launchSearch() {
        const ctx = this;
        if (this.isFormValid()) {
            this.suiviEbicsDataRaw = new SuiviEbicsDataRaw();
            this.suiviEbicsDataRaw.dateDemarrageExacte = this.convertFormatDate.getFormatDateFr(this.filters.dateDemarrageExacte);
            this.suiviEbicsDataRaw.dateDemarrageFrom = this.convertFormatDate.getFormatDateFr(this.filters.dateDemarrageFrom);
            this.suiviEbicsDataRaw.dateDemarrageTo = this.convertFormatDate.getFormatDateFr(this.filters.dateDemarrageTo);
            this.suiviEbicsDataRaw.hostId = this.filters.hostId ? this.filters.hostId.trim() : null;
            this.suiviEbicsDataRaw.ebicsStatut = this.filters.ebicsStatut ? this.filters.ebicsStatut.trim() : null;
            this.suiviEbicsDataRaw.erreurCategorie = this.filters.erreurCategorie ? this.filters.erreurCategorie.trim() : null;
            this.suiviEbicsDataRaw.erreur = this.filters.erreur ? this.filters.erreur.trim() : null;

            this.updateFilterList(this.filters);

            const dataSource: IServerSideDatasource = {
                getRows: function (paramsRows: IServerSideGetRowsParams) {
                    ctx.sorts = gridConvertSort(ctx.suiviEbicsGridHelper.gridSortModel(), []);
                    const sortsSuiviEbics = ctx.sorts ? ctx.sorts : null;
                    const criteria = {
                        page: ctx.suiviEbicsGridHelper.gridApi.paginationGetCurrentPage() || 0,
                        size: ctx.suiviEbicsGridHelper.gridApi.paginationGetPageSize(),
                        sortsSuiviEbics
                    };
                    ctx.ebicsService.getSuiviEbicsFilteredList(criteria, ctx.suiviEbicsDataRaw).subscribe(
                        (data) => {
                            ctx.rowCount = data.totalElements > 0 ? data.totalElements : -1;
                            ctx.suiviEbicsGridHelper.manageNoRowsOverlay(data.totalElements);
                            paramsRows.success({
                                "rowData": data.content,
                                "rowCount": data.totalElements
                            });
                            const pageN = Number.parseInt(sessionStorage.getItem('suiviEbicsCurrentPage'));
                            if (!isNullOrUndefined(pageN) && pageN !== ctx.suiviEbicsGridHelper.gridApi.paginationGetCurrentPage() && pageN > 0) {
                                ctx.suiviEbicsGridHelper.gridApi.paginationGoToPage(pageN - 1);
                            }
                            sessionStorage['suiviEbicsCurrentPage'] = null;
                        },
                        () => paramsRows.fail()
                    );
                }
            };
            this.suiviEbicsGridHelper.gridApi.setServerSideDatasource(dataSource);
            sessionStorage['suiviEbicsFiltre'] = JSON.stringify(this.filters);
            this.suiviEbicsGridHelper.gridApi.sizeColumnsToFit();
        }
    }

    updateFilterList(filters) {
        const ctx = this;
        ctx.filterList = [];
        Object.keys(filters).forEach((key) => {
            if (filters[key] !== null && filters[key] !== '') {
                ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
            }
        });
        // Remove duplicated ids
        ctx.filterList = uniqBy(ctx.filterList, 'id');
        // filter null
        ctx.filterList = ctx.filterList.filter((item) => {
            return item.id != null;
        });
    }

    translateFilterLabelValue(key, value) {
        let translatedKey = key;
        let formattedValue = value;
        let formattedKey = key;
        switch (key) {
            case 'dateDemarrageExacte':
                translatedKey = 'Date réponse';
                formattedValue = this.formatDateForTags({ value: value });
                break;
            case 'dateDemarrageFrom':
                translatedKey = 'Date réponse apres le';
                formattedValue = this.formatDateForTags({ value: value });
                break;
            case 'dateDemarrageTo':
                translatedKey = 'Date réponse avant le';
                formattedValue = this.formatDateForTags({ value: value });
                break;
            case 'hostId':
                translatedKey = 'HOST ID';
                break;
            case 'ebicsStatut':
                translatedKey = 'Statut';
                break;
            case 'erreurCategorie':
                translatedKey = 'Catégorie d\'erreur';
                break;
            case 'erreur':
                translatedKey = 'erreur';
                break;
            default:
                break;
        }
        return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
    }

    formatDateForTags = (params): string => {
        if (params.value !== null && params.value != 'Invalid Date') {
            return this.datePipe.transform(params.value, 'dd/MM/yyyy');
        }
    };

    onChoiceChange($event: any, type: string): void {
        if ($event.filterChoice === "EXACTE") {
            this.filters[type + 'From'] = null;
            this.filters[type + 'To'] = null;
        } else {
            this.filters[type + 'Exacte'] = null;
        }
    }

    checkDateRangeValidity(event) {
        this.isDateRangeValid = event;
        if (this.dateReponse) {
            this.dateReponse.hideTooltlipDebut();
            this.dateReponse.hideTooltlipFin();
        }
    }

    initFilter() {
        this.resetFilter();
        this.updateFilterList(this.filters);
    }

    isFormValid(): boolean {
        let inputValue = true;
        if (this.filters.dateDemarrageFrom && this.filters.dateDemarrageTo) {
            if (this.isDateRangeValid) {
                this.dateReponse.hideTooltlipDebut();
                this.dateReponse.hideTooltlipFin();
            } else {
                this.dateAccordion.expansionPannel.open();
                this.dateReponse.showTooltipDebut();
                this.dateReponse.showTooltipFin();
                inputValue = false;
            }
        }
        return inputValue;
    }

    fillFilterObjectFromTagsOnly() {
        this.filters = new SuiviEbicsDataRaw();
        this.filterList.forEach((filter) => {
            this.filters[filter.id] = filter.value2;
        });
    }
    resetAllFilter(): void {
        this.initFilter();
        this.filterList = [];
        this.filters = new SuiviEbicsDataRaw();
        this.fillFilterObjectFromTagsOnly();
        this.resetFilter();
        this.filters.dateDemarrageExacte = new Date();
        sessionStorage['suiviEbicsFiltre'] = JSON.stringify(this.filters);
        this.isDateRangeValid = true;
        this.launchSearch();
    }

    resetFilter() {
        this.filterList = [];
        this.filterListTmp = [];
        sessionStorage['suiviEbicsFiltre'] = null;
    }

    onKeyDown(event) {
        this.launchSearch();
    }

    toggleFilterDisplay() {
        if (this.initHidden) {
            this.initHidden = false;
            setTimeout(() => {
                this.showFilter = !this.showFilter;
            }, 10);
        } else {
            this.showFilter = !this.showFilter;
        }
    }

    onPaginationSizeChange(paginationSize: number): void {
        this.suiviEbicsGridHelper.changePaginationSize(paginationSize);
        this.launchSearch();
        this.suiviEbicsGridHelper.saveGridStatePrefix();
    }

    resetGridState() {
        this.suiviEbicsGridHelper.resetGridState();
        this.gridDataRaw.size = this.suiviEbicsGridHelper.defaultPaginationPageSize;
    }

    removeFilter(id) {
        delete this.filters[id];
        const currentFilterList = this.filterList;
        const index = _.findIndex(currentFilterList, (filt) => filt.id === id);
        currentFilterList.splice(index, 1);
        this.filterList = currentFilterList;
        sessionStorage['suiviEbicsFiltre'] = JSON.stringify(this.filters);
        if (Array.isArray(this.filterList) && this.filterList.length > 0) {
            this.fillFilterObjectFromTagsOnly();
            this.launchSearch();
        }else this.clearGridFromData();
    }
}
