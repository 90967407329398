import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { RappComptePsDataRaw } from '../../../data/rappComptePsDataRaw';
import { RapprochementComptePsService } from '../rapprochement.service';
import { StorageService } from './../../../shared/storage-service';
import { environment } from 'src/main/webapp/src/environments/environment';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-rapp-bank-account',
  templateUrl: './rapp-bank-account.component.html',
  styleUrls: ['./rapp-bank-account.component.scss'],
})
export class RappBankAccountComponent implements OnInit, OnDestroy {
  errorMessage: any;
  rapprochementComptePs: RappComptePsDataRaw;
  private _oxlinUserAccounts: any[];
  oxlinUserConnections: any[];
  oxlinUserConnAcc: any[];
  private _oxlinUserWidgetUrl: string;
  oxlinUserWidgetSafeUrl: SafeResourceUrl;
  showAddBankAccountModal: boolean = false;
  showRestoreBankAccountModal: boolean = false;
  displayRestoreAccountSuccess: boolean = false;
  isPageLoaded: boolean = false;
  // isUserConnEmpty: boolean = false;
  idConnToRestore: string;
  oxlinUserConnected: any;
  
  userConnected: any;
  host: string;

  showConfirmModal: boolean = false;
  modalTitle: string;
  modalMessage: string;
  accountToDelete: any;
  routeSub: any;
  private subscrpt: Subscription = new Subscription();
  migrationWidgetUrl = '';
  refreshDone = false;

  showSupportButton=false;

  showOxlinLinkTest = environment.showOxlinLinkTest;

  constructor(
    private rapprochementComptePsService: RapprochementComptePsService,
    private toastr: ToastrService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
  ) {
    // Body styling
    const body = document.body
    body.className = 'app';

    this.rapprochementComptePs = new RappComptePsDataRaw();
  }

  ngOnInit() {
    this.host = window.location.origin;
    this.host = this.host.includes('localhost') ? this.host : (this.host += '/careweb-client');
    this.userConnected = JSON.parse(localStorage.getItem('careweb_user'));
    this.oxlinUserConnected = JSON.parse(localStorage.getItem('current_ps')).rappComptePs;

    this.initialiseOxlinUserContext(this.getPsId());
    this.showOxlinWdget();
    this.valideConnections();
  }
  showOxlinWdget(){
    if (this.activatedRoute.snapshot.queryParams.result !== 'error' && this.activatedRoute.snapshot.queryParams.connection_id) {
      this.storageService.remove('oxlin_widget_url', true);
    } else if (this.storageService.get('oxlin_widget_url', true)) {
      this._oxlinUserWidgetUrl = this.storageService.get('oxlin_widget_url', true);
      this.oxlinUserWidgetSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this._oxlinUserWidgetUrl);
      this.showAddBankAccountModal = true;
    }
  }

  getPsId() {
    return (localStorage.getItem('supervised_ps') != null && localStorage.getItem('supervised_ps') !='-1') ? localStorage.getItem('supervised_ps').split(';')[1]:(localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs;
    //return (localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs
  }

  
  public initialiseOxlinUserContext(idUser: Number) {
    this.showSupportButton=false
    this.oxlinUserConnAcc = [];
    this._oxlinUserAccounts = [];
    this.oxlinUserConnections = [];
    this.rapprochementComptePsService.getUserOxlinScope(idUser, true)
      .subscribe((data) => {
        if (data[0] == 'connections') this.oxlinUserConnections = (data[1] != "") ? data[1] : [];
        if (data[0] == 'accounts') this._oxlinUserAccounts = (data[1] != "") ? _.sortBy(data[1], s => s.name) : [];
        if (data[0] == 'widget') {
          this.subscrpt = this.activatedRoute.queryParams.subscribe(params => {
            if (!this.storageService.get('oxlin_widget_url', true)){
              this._oxlinUserWidgetUrl = data[1] + '&redirect_url=' + this.host + '/rapp-bank-account&aspsp_callback_uri=' + this.host + '/rapp-bank-account&consent_per_account=false&wait_sync_end=true&expected_account_types=PAYMENT&congrats_bypass_delay=0&countries=FRA&locale=fr'
                  + '&display_border=true&button_alignment=RIGHT_BOTTOM&font_secondary_color=darkturquoise&elements_color=darkturquoise&elements_font_color=White&secondary_elements_color=darkturquoise&secondary_elements_font_color=White&display_integrator_logo=false';
            }
            if (params.result === 'error') {
              this.oxlinUserWidgetSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this._oxlinUserWidgetUrl);
              this.router.navigate(['/rapp-bank-account'], { queryParams: {}, replaceUrl: true }).then(() => {
                window.location.reload();
              });
            }
            const token = localStorage.getItem('oxlin_token');
            if (token == 'null' || token == null) {
              this.isPageLoaded = false;
            }
            this.subscrpt.unsubscribe();
          });
        }

        this.aggregateConnAccounts(this.oxlinUserConnections, this._oxlinUserAccounts);
        if (data[0] == 'accounts') {
          this.isPageLoaded = true;
        }
      },
        (error) => {
          this.showSupportButton=true
          this.errorMessage = error;
        });

  }
  public addNewAccount() {
    this.showAddBankAccountModal = true;
    this.oxlinUserWidgetSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this._oxlinUserWidgetUrl);
    this.storageService.set('oxlin_widget_url', this._oxlinUserWidgetUrl, true);

    //console.log("OXLIN WIDGET URL", this._oxlinUserWidgetUrl);
    //console.log("OXLIN WIDGET URL SAFE", this.oxlinUserWidgetSafeUrl);
  }

  public renewAuthOxlinUserAccount(conn: any) {
    this.showAddBankAccountModal = true;
    var edit_authOxlinUrl = this._oxlinUserWidgetUrl.replace('add_connection', 'edit_credentials');
    edit_authOxlinUrl += '&connection_id=' + conn.id + '&provider_id=' + conn.provider_id;
    this.oxlinUserWidgetSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(edit_authOxlinUrl);
    this.storageService.set('oxlin_widget_url', this._oxlinUserWidgetUrl, true);
  }

  public openModalRestoreAccounts() {
    this.showRestoreBankAccountModal = true;
  }

  public restoreOxlinUserAccount(idConnToRestore: string) {
    this.rapprochementComptePsService.restoreOxlinUserAccounts(idConnToRestore).toPromise()
      .then((code) => {
        if (code == 204) {
          this.displayRestoreAccountSuccess = true;
        } else {
          this.toastr.error('Une erreur s\'est produite lors de la restauration des comptes. Si le problème persiste, veuillez contacter votre support', 'Restauration de comptes');
        }
      })
  }

  public contactSupport() {
    this.router.navigate(['/contact-support']);
  }

  public closeModal() {
    this.showAddBankAccountModal = false;
    this.showRestoreBankAccountModal = false;
    this.oxlinUserWidgetSafeUrl = [];
    this.storageService.remove('oxlin_widget_url', true);

    const accountsToConsent = _.filter(this._oxlinUserAccounts, { 'status': 'PENDING_CONSENT' });
    if (accountsToConsent.length > 0) {
      const groupedAccountsToConsent = _.groupBy(accountsToConsent, 'connection_id');
      Object.keys(groupedAccountsToConsent).forEach((key: string) => {
        this.rapprochementComptePsService.updateOxlinAccountConsent(key, { consents: groupedAccountsToConsent[key].map(account => ({ account_id: account.id, consent: false })) }).subscribe();
      });
    }
    // this.initialiseOxlinUserContext(this.getPsId());
  }

  public closeModalRestoreAccounts() {
    this.showRestoreBankAccountModal = false;
    this.displayRestoreAccountSuccess = false;
    this.isPageLoaded = false;
    this.idConnToRestore = null;
    this.initialiseOxlinUserContext(this.getPsId());
  }

  public refreshAccounts() {
    window.location.reload();
    //this.initialiseOxlinUserContext(this.getPsId());
  }

  deleteOxlinBankAccount(idAccount: string) {
    this.rapprochementComptePsService.deleteOxlinUserAccount(idAccount).toPromise()
      .then((code) => {
        if (code == 204) {
          this.removeAccountAndConnectionIfLastAccount(idAccount);
          this.oxlinUserConnAcc = [];
          this.aggregateConnAccounts(this.oxlinUserConnections, this._oxlinUserAccounts);
          this.toastr.success('Compte bancaire supprimé avec succès', 'Supprimer un compte bancaire');
          /*if(this.oxlinUserConnAcc.length==0 && this.oxlinUserConnections.length==0){
            this.isUserConnEmpty=true;
          }*/
        } else {

        }
      })
  }

  private aggregateConnAccounts(oxlinUserConn: any[], oxlinUserAcc: any[]) {
    if (oxlinUserConn.length > 0 && oxlinUserAcc.length > 0 && this.oxlinUserConnAcc.length == 0) {
      _.filter(oxlinUserAcc, (oxlinAccount) => oxlinAccount.status !== 'PENDING_CONSENT').forEach(account => {
        this.oxlinUserConnAcc.push([account, _.find(oxlinUserConn, ['id', account.connection_id])])
      });
    }
    this.oxlinUserConnAcc = _.sortBy(this.oxlinUserConnAcc, s => s[1].name);
  }

  public showModal(showModal: boolean) {
    this.showConfirmModal = showModal;
  }

  public openConfirmationModal(accountToDelete: any) {
    this.showConfirmModal = true;
    this.modalTitle = 'Souhaitez vous désynchroniser ce compte bancaire?'
    this.accountToDelete = accountToDelete;
    const MESSAGE_WHEN_RESTAURABLE = 'Vous ne recevrez plus les virements en provenance de ce compte. Cependant vous pourrez le restaurer à tout moment en utilisant le bouton "Restaurer les comptes supprimés".';
    const MESSAGE_WHEN_NOT_RESTAURABLE = 'Vous ne recevrez plus les virements en provenance de ce compte. Pour le restaurer il faudra vous authentifiez à nouveau en utilisant le bouton "Ajouter une nouvelle banque".';

    this.modalMessage =
      _.filter(this._oxlinUserAccounts, { 'connection_id': accountToDelete.connection_id }).length > 1
        ? MESSAGE_WHEN_RESTAURABLE :
        MESSAGE_WHEN_NOT_RESTAURABLE;

  }

  onConfirmModal() {
    if (this.accountToDelete) {
      this.showModal(false);
      this.deleteOxlinBankAccount(this.accountToDelete.id);
      this.rapprochementComptePsService.getUserOxlinScope(this.getPsId(), true)
      .subscribe((data) => {
        if (data[0] == 'connections') this.oxlinUserConnections = (data[1] != "") ? data[1] : [];
        if (data[0] == 'accounts') this._oxlinUserAccounts = (data[1] != "") ? _.sortBy(data[1], s => s.name) : [];
        if (data[0] == 'widget') {
          this._oxlinUserWidgetUrl = data[1] + '&redirect_url=' + this.host + '/rapp-bank-account&aspsp_callback_uri=' + this.host + '/rapp-bank-account&consent_per_account=false&wait_sync_end=true&expected_account_types=PAYMENT&congrats_bypass_delay=0&countries=FRA&locale=fr'
            + '&display_border=true&button_alignment=RIGHT_BOTTOM&font_secondary_color=darkturquoise&elements_color=darkturquoise&elements_font_color=White&secondary_elements_color=darkturquoise&secondary_elements_font_color=White&display_integrator_logo=false';
        }

        this.aggregateConnAccounts(this.oxlinUserConnections, this._oxlinUserAccounts);
        if (data[0] == 'accounts' /*&& data[1].length==0 */) {
          this.isPageLoaded = true;
        }
      },
        (error) => {
          this.errorMessage = error;
        });
    }
  }

  private removeAccountAndConnectionIfLastAccount(AccountId: string) {
    if (AccountId) {
      let connectionId = _.find(this._oxlinUserAccounts, (o) => o.id == AccountId).connection_id;
      _.pullAt(this._oxlinUserAccounts, _.findIndex(this._oxlinUserAccounts, (o) => o.id == AccountId));
      let accountsPerConnection = _.filter(this._oxlinUserAccounts, (o) => o.connection_id == connectionId);
      accountsPerConnection.length == 0 ? _.pullAt(this.oxlinUserConnections, _.findIndex(this.oxlinUserConnections, (o) => o.id == connectionId)) : null;
    }
  }

  migrateToRedirectChannel(migration) {
    this.composeMigrationWidgetUrl(migration.connection, migration.redirectChannel);
    this.openMigrationModal(this.migrationWidgetUrl);
  }

  composeMigrationWidgetUrl(connection, redirectChannel) {
    this.migrationWidgetUrl = this._oxlinUserWidgetUrl.replace('add_connection', 'edit_credentials');
    this.migrationWidgetUrl += `&connection_id=${connection.id}&provider_id=${connection.provider_id}&expected_account_types=PAYMENT`;
  }

  openMigrationModal(widgetUrl) {
    this.showAddBankAccountModal = true;
    this.oxlinUserWidgetSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(widgetUrl);
    this.storageService.set('oxlin_widget_url', this._oxlinUserWidgetUrl, true);
  }

  valideConnections() {
    const filteredConnections = [];
    for (const connection of this.oxlinUserConnections) {
      const correspondingAccount = this._oxlinUserAccounts.find(account => account.connection_id === connection.id);
      if (correspondingAccount && correspondingAccount.status !== 'PENDING_CONSENT') {
        filteredConnections.push(connection);
      }
    }
    return filteredConnections;
  }

  ngOnDestroy() {
    this.closeModal();
  }

  refreshEvent() {
    this.refreshDone = false;
    this.rapprochementComptePsService.refreshConnections().subscribe(()=> {
      this.refreshDone = true;
    });
  }

  copyOxlinLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'https://' + window.location.hostname + '/careweb-back/careweb/api/v1/rapp/rapprochements/oxlin/' + this.getPsId();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(null, "L'URL des transactions de test a bien été copié");

  }

}
